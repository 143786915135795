import { policyList } from "@/enums/permissions";
import { Product } from "@/interfaces/product";
import {
  ParsedBatch,
  ReconcileResponse,
  ReconciliationData,
  SaveReconciliationData
} from "@/interfaces/reconciliation";
import { i18n } from "@/plugins/i18n";
import HttpService from "@/services/http.service";
import { store } from "@/store";
import { AxiosResponse } from "axios";
import { TableAction } from "helix-vue-components";
import { Vue } from "vue-property-decorator";
import { messagesService } from "./messages.service";
import { productService } from "./product.service";

class InventoryReconciliationService extends HttpService {
  protected loadDispatcher: string = "ReconciliationModule/loadReconciliations";

  public async saveReconciliationBatch(formData: SaveReconciliationData) {
    try {
      const response = await Vue.axios({
        method: "POST",
        url: `${
          process.env.VUE_APP_BASE_URL
        }/inventory/reconciliation/create_batch`,
        data: formData
      });

      messagesService.renderSuccessMessage(
        i18n.t("metrc.create_batch_messages").toString()
      );
      return response.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  public setQuery(query: { date: string; page: number; itemsPerPage: number }) {
    this.query = {
      ...query,
      per_page: query.itemsPerPage
    };
  }

  public async getWithDateFilter(): Promise<ReconciliationData | undefined> {
    const response: AxiosResponse<ReconcileResponse> = await Vue.axios({
      method: "GET",
      url: `${
        process.env.VUE_APP_BASE_URL
      }/inventory/batches/traceability/reconcilable`,
      params: {
        ...this.query
      }
    });

    super.setPagination({ data: response.data.data.reconcilable });

    if (response.data.code === 200) {
      const inSync = response.data.data.reconcilable.data.reduce(
        (sum, reco) => {
          if (reco.status.includes("IN_SYNC")) {
            sum += 1;
          }
          return sum;
        },
        0
      );
      return {
        data: response.data.data.reconcilable.data,
        total: response.data.data.total,
        reconcilable: response.data.data.reconcilable.total - inSync,
        synchronized: inSync
      };
    }
  }

  /**
   * getProducts
   */
  public getProducts(name: string = ""): Promise<Product[]> {
    return productService.findByName(name, {
      sort: "name"
    });
  }

  public getRowActions(): TableAction[] {
    return [
      {
        icon: "fas fa-tools",
        id: "inventory_reconciliation_item_action",
        label: "Reconcile",
        tooltip: "Reconcile",
        action(batch: ParsedBatch) {
          store.dispatch("RouterModule/go", {
            name: "reco-batch",
            params: {
              batchuid: batch.biotrack_id
            }
          });
        },
        visibleCondition: (batch: { item: ParsedBatch }) => {
          return (
            (store.getters["PermissionsModule/hasPermission"](
              policyList.modifyBatchDetails
            ) &&
              batch.item.item_reconciles === "No") ||
            batch.item.quantity_reconciles === "No" ||
            batch.item.unit_of_measure_reconciles === "No" ||
            batch.item.status.includes("MISSING_TRACKING_ID")
          );
        }
      },
      {
        icon: "fas fa-box",
        id: "inventory_reconciliation_item_action",
        label: "Create Batch",
        tooltip: "Create Batch",
        action(batch: ParsedBatch) {
          store.dispatch("RouterModule/go", {
            name: "create-batch",
            params: {
              date: batch.fromDate,
              packageTag: batch.package_tag
            }
          });
        },
        visibleCondition: (batch: { item: ParsedBatch }): boolean => {
          return (
            store.getters["PermissionsModule/hasPermission"](
              policyList.modifyBatchDetails
            ) &&
            !batch.item.batch_data &&
            !batch.item.status.includes("MISSING_TRACKING_ID")
          );
        }
      },
      {
        icon: "fa-check",
        id: "inventory_reconciliation_item_action",
        label: "Synchronized",
        tooltip: "Synchronized",
        visibleCondition: (batch: { item: ParsedBatch }) => {
          return batch.item.synchronized;
        }
      }
    ];
  }
}

export const inventoryReconciliationService: InventoryReconciliationService = new InventoryReconciliationService();
