import { Batch } from "@/interfaces/batch";
import { ReconciliationData } from "@/interfaces/reconciliation";
import { ReconciliationState } from "@/vuex/modules/inventory/reconciliation/reconciliation.types";
import { TablePagination } from "helix-vue-components";
import { MutationTree } from "vuex";

type ReconciliationMutationTree = MutationTree<ReconciliationState>;

export const mutations: ReconciliationMutationTree = {
  setHeaderData(state: ReconciliationState, data: ReconciliationData) {
    state.headerData.reconcilable = data.reconcilable;
    state.headerData.synchronized = data.synchronized;
    state.headerData.total = data.total;
  },
  setReconciliations(state: ReconciliationState, reconciliations: Batch[]) {
    state.reconciliations = reconciliations;
  },
  setLoading(state: ReconciliationState, loading: boolean) {
    state.loading = loading;
  },
  setPagination(
    state: ReconciliationState,
    pagination: TablePagination | null
  ) {
    state.pagination = pagination;
  }
};
