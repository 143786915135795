import {
  Batch,
  ParsedBatch,
  ReconciliationData
} from "@/interfaces/reconciliation";
import { inventoryReconciliationService } from "@/services/InventoryReconciliation.service";
import { messagesService } from "@/services/messages.service";
import { ReconciliationState } from "@/vuex/modules/inventory/reconciliation/reconciliation.types";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { ActionContext, ActionTree } from "vuex";

type ReconciliationActionContext = ActionContext<
  ReconciliationState,
  RootState
>;
type ReconciliationActionTree = ActionTree<ReconciliationState, RootState>;

export const actions: ReconciliationActionTree = {
  /**
   * @param context
   * @param noQuery GET without query params
   */
  async loadReconciliations(
    context: ReconciliationActionContext,
    date: string
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      const payload:
        | ReconciliationData
        | undefined = await inventoryReconciliationService.getWithDateFilter();

      // Maps data into a parsed/plain format in order to use it in table,
      // comparations are based on backend-side with status array

      if (payload) {
        const parsedBatches = payload.data.map((batch: Batch) => {
          let parsedBatch: ParsedBatch = {
            fromDate: date,
            package_tag:
              (batch.local
                ? batch.local.tracking_id === null
                  ? ""
                  : batch.local.tracking_id
                : "") ||
              ((batch.remote && batch.remote.tag) || ""),
            biotrack_id: batch.local
              ? batch.local.batch_uid === null
                ? ""
                : batch.local.batch_uid
              : "",
            item_reconciles: "",
            quantity_reconciles: "",
            unit_of_measure_reconciles: "",
            synchronized: batch.status.includes("IN_SYNC"),
            status: batch.status
          };
          // if product has tag and status has NON_EXISTENT_BATCH no batch_data is defined.
          // Inversed comparison!!
          if (
            (!(batch.remote && batch.remote.tag) ||
              !batch.status.includes("NON_EXISTENT_BATCH")) &&
            !batch.status.includes("MISSING_TRACKING_ID")
          ) {
            parsedBatch = Object.assign(parsedBatch, {
              item_reconciles: batch.status.includes("NOT_MATCHING_NAMES")
                ? "No"
                : "Yes",
              quantity_reconciles: batch.status.includes(
                "NOT_MATCHING_QUANTITIES"
              )
                ? "No"
                : "Yes",
              unit_of_measure_reconciles:
                batch.status.includes("TRACKED_BY_COUNT") ||
                batch.status.includes("TRACKED_BY_WEIGHT")
                  ? "No"
                  : "Yes",
              batch_data: [
                {
                  batchname_values: "Metrc Values",
                  batch_product_name: batch.remote
                    ? batch.remote.metrc_item
                    : "",
                  quantity: batch.remote ? batch.remote.quantity : 0,
                  unit_of_measure: batch.remote ? batch.remote.unit : "",
                  empty: ""
                },
                {
                  batchname_values: "Biotrack Values",
                  batch_product_name: batch.local
                    ? batch.local.metrc_item_mapped || ""
                    : "",
                  quantity: batch.local ? batch.local.quantity || 0 : 0,
                  unit_of_measure: batch.local ? batch.local.unit || "" : "",
                  empty: ""
                }
              ]
            });
          }
          return parsedBatch;
        });
        const pagination: TablePagination = await inventoryReconciliationService.getPagination();
        context.commit("setPagination", pagination);
        context.commit("setHeaderData", payload);
        context.commit("setReconciliations", parsedBatches);
      }
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },
  async resetReconciliations(context: ReconciliationActionContext) {
    context.commit("setReconciliations", []);
  }
};
