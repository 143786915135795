import { ReconciliationModule } from "@/vuex/modules/inventory/reconciliation/reconciliation.index";
import { Component, Vue } from "vue-property-decorator";

@Component({
  render: c => c("router-view")
})
export default class InventoryReconciliationComponent extends Vue {
  protected beforeCreate() {
    this.$store.registerModule("ReconciliationModule", ReconciliationModule);
  }
  protected beforeDestroy() {
    this.$store.unregisterModule("ReconciliationModule");
  }
}
