import { Batch } from "@/interfaces/batch";
import { ReconciliationData } from "@/interfaces/reconciliation";
import { ReconciliationState } from "@/vuex/modules/inventory/reconciliation/reconciliation.types";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { GetterTree } from "vuex";

type ReconciliationGetter = GetterTree<ReconciliationState, RootState>;

export const getters: ReconciliationGetter = {
  reconciliations(state: ReconciliationState): Batch[] {
    return state.reconciliations;
  },
  headerData(state: ReconciliationState): ReconciliationData {
    return state.headerData;
  },
  pagination(state: ReconciliationState): TablePagination | null {
    return state.pagination;
  },
  loading(state: ReconciliationState): boolean {
    return state.loading;
  }
};
